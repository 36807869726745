import Vue from "vue";
import VueRouter from "vue-router";
// import MedsHome from "@/views/Meds/MedsHome.vue";
// import Lenses from "@/views/CLs/Lenses.vue";
import Home from "@/views/Pages/Home.vue";

Vue.use(VueRouter);

function guardSubscribed(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("isSubscribed"))
    isAuthenticated = localStorage.getItem("isSubscribed") === "true";
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    // console.log("unauthorized", to);
    next("/unauthorized?target=" + encodeURIComponent(to.fullPath)); // go to '/login';
  }
}

// function guardLoggedIn(to, from, next) {
//   var isLoggedIn = false;
//   if (localStorage.getItem("eyedockUserData")) {
//     let obj = JSON.parse(localStorage.getItem("eyedockUserData"));
//     isLoggedIn = obj?.login;
//   }
//   if (isLoggedIn) {
//     next(); // allow to enter route
//   } else {
//     // todo - redirect to login "to" once logged in
//     next("/home?target=" + encodeURIComponent(to.fullPath)); // go to '/login';
//   }
// }

const routes = [
  {
    path: "/(Home|home)?",
    name: "home",
    component: Home,
    meta: {},
  },
  {
    path: "/partners/:partner",
    name: "ReferrerHome",
    component: Home,
  },

  // ----- cls -------------------

  {
    path: "/hybrids/:hybridSlug?",
    name: "hybrids",
    component: () => import("@/views/CLs/Lenses.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/cls/privateLabels",
    name: "privateLabels",
    component: () => import("@/views/CLs/PrivateLabels.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/cls",
    name: "lenses",
    component: () => import("@/views/CLs/Lenses.vue"),
    // beforeEnter: guardLoggedIn,
    children: [
      {
        path: "search/:term?",
        name: "clSearch",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
      {
        path: "rx",
        name: "clsByRx",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
      {
        path: "(advancedSearch|advanced-search|advanced)",
        name: "advanced",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
      {
        path: "details/:lensSlug",
        name: "lensDetails",
        component: () => import("@/views/CLs/Lenses.vue"),
      },

      {
        path: ":type(any|sphere|toric|bifocal_sphere|bifocal_toric|cosmetic_sphere|cosmetic_toric)/:replace(any_replace|daily|2weeks|monthly|disposables|quarterly|conventional)?",
        name: "lensTypes",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
      {
        path: "companies",
        name: "clCompanies",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
      {
        path: "company/:company",
        name: "clCompany",
        component: () => import("@/views/CLs/Lenses.vue"),
      },
    ],
  },
  {
    path: "/search-all",
    name: "SearchAll",
    component: () => import("@/views/SearchAll/SearchAllHome.vue"),
    meta: {
      hideNavbarOnMobile: true,
    },
  },
  {
    // RevolutionEHR legacy link
    path: "/search-all-lenses",
    name: "SearchAllLenses",
    component: () => import("@/views/CLs/SearchAllLenses.vue"),
  },

  // ------- meds ----------
  {
    path: "/meds",
    name: "MedsHome",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/orals",
    name: "Orals",
    component: () => import("@/views/Meds/MedsOrals.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/orals/details/:oralId(\\d+)/:slug?",
    name: "OralDetails_id",
    // beforeEnter: guardLoggedIn,
    //component: MedDetails
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OralDetails_id" */ "@/views/Meds/MedsOrals.vue"
      ),
  },
  {
    path: "/meds/companies",
    name: "MedCompanies",
    component: () =>
      import(
        /* webpackChunkName: "MedCompanies" */ "@/views/Meds/MedCompanies.vue"
      ),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/ingredients",
    name: "MedIngredients",
    props: (route) => ({ query: route.query.ingredient }),
    component: () =>
      import(
        /* webpackChunkName: "MedIngredients" */ "@/views/Meds/MedIngredients.vue"
      ),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/moas",
    name: "MedMoas",
    props: (route) => ({ query: route.query.moa }),
    component: () => import("@/views/Meds/MedIngredients.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/bottle-cap-colors",
    name: "CapColors",
    props: true,
    component: () => import("@/views/Meds/CapColors.vue"),

    // beforeEnter: guardLoggedIn,
  },

  {
    path: "/meds/companies/:company_id(\\d+)/:companySlug?",
    name: "MedCompany",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
  },

  {
    path: "/meds/category/:category",
    name: "MedCategory",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "MedCategory" */ '../views/MedSearch.vue')
  },
  {
    path: "/meds/category/:category_id(\\d+)/:categorySlug?",
    name: "MedCategoryById",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "MedCategoryById" */ '../views/MedSearch.vue')
  },
  {
    path: "/meds/ingredient/:ingredient_id(\\d+)/:ingredientSlug?",
    name: "MedIngredient",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "MedIngredient" */ '../views/MedSearch.vue')
  },
  {
    path: "/meds/moa/:moa_id(\\d+)/:moaSlug?",
    name: "MedMoa",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "MedMoa" */ '../views/MedSearch.vue')
  },
  {
    path: "/meds/details/:id(\\d+)/:slug?",
    name: "MedDetails_id",
    //component: MedDetails
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MedDetails_id" */ "../views/Meds/MedDetails.vue"
      ),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/details/:name",
    name: "MedDetails_name",
    //meta: { title: 'Details - EyeDock/Meds' },
    //component: MedDetails
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Meds/MedDetails.vue"),
    // beforeEnter: guardLoggedIn,
  },
  {
    path: "/meds/:term",
    name: "MedSearch",
    component: () => import("@/views/Meds/MedsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "MedSearch" */ '../views/MedSearch.vue')
  },

  // ------- icd and billing stuff ---------
  {
    path: "/coding",
    name: "CodesHome",
    component: () => import("@/views/Icd/CodesHome.vue"),
  },

  {
    path: "/icd10",
    name: "ICD10Home",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  // children: [
  {
    path: "/icd10/chapters",
    name: "CodeChapters",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  {
    path: "/icd10/diabetic-retinopathy-widget",
    name: "diabetesWidget",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  // {
  {
    path: "/icd10/:group(diabetes|headaches)",
    name: "IcdGroup",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },

  {
    path: "/icd10/search/:search",
    props: true,
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  {
    path: "/icd10/:range([0-9A-Za-z.]*-[0-9A-Za-z.]*)",
    name: "codeRange",
    props: true,
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  {
    path: "/icd10/:collection(.*,.*)",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  {
    path: "/icd10/:range([0-9A-Za-z.]{3,8})",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },

  {
    path: "/icd10/chapters/:chapter",
    name: "CodeChapter",
    component: () => import("@/views/Icd/IcdBrowse.vue"),
    // beforeEnter: guardSubscribed,
  },
  {
    path: "/billing-codes",
    name: "BillingCodes",
    component: () => import("@/views/Icd/BillingCodes.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/coding-clinical-examples",
    name: "CodingClinicalExamples",
    component: () => import("@/views/Icd/CodingClinicalExamples.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/coding-widget",
    name: "CodingWidget",
    component: () => import("@/views/Icd/CodingWidget.vue"),
    beforeEnter: guardSubscribed,
  },

  // ],
  // },

  // ---- calcs stuff --------------------------------
  {
    path: "/calcs",
    name: "CalcsHome",
    component: () => import("@/views/Calcs/CalcsHome.vue"),
    // beforeEnter: guardLoggedIn,
    // children: [
  },
  // {
  //   path: "/calcs/:calc(plaquenil-calcs)",
  //   name: "PlaquenilCalcs",
  //   component: () => import("@/views/Calcs/CalcsHome.vue"),
  // },
  {
    path: "/calcs/meridian-power-calculator",
    name: "MeridianPowerCalcs",
    component: () => import("@/components/calcsStuff/MeridianPowerCalcs.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/calcs/oblique",
    name: "ObliqueCrossCyl",
    component: () => import("@/components/calcsStuff/ObliqueCrossCyl.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/calcs/samfap",
    name: "SAMFAP",
    component: () => import("@/components/calcsStuff/Samfap.vue"),
  },
  {
    path: "/calcs/contact-lens",
    name: "ClCalc",
    component: () => import("@/components/calcsStuff/ClCalcs/ClCalcs.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/calcs/parks-3-step",
    name: "Parks3Step",
    component: () => import("@/components/calcsStuff/Parks3step.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/calcs/plaquenil-calcs",
    name: "Plaquenil",
    component: () => import("@/components/calcsStuff/Plaquenil.vue"),
  },
  {
    path: "/refractive-error-simulator",
    name: "VisionSimulator",
    component: () =>
      import("@/components/calcsStuff/VisionSimulator/VisionSimulator.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/calcs/:calc(vertex)",
    name: "Vertex",
    component: () => import("@/views/Calcs/CalcsHome.vue"),
  },
  {
    path: "/calcs/:calc",
    name: "CalcsPage",
    component: () => import("@/views/Calcs/CalcsHome.vue"),
    beforeEnter: guardSubscribed,
  },
  //   ],
  // },

  // ----- pages (documentation, FAQs, etc) -------
  {
    path: "/about_eyedock",
    name: "AboutEyedock",
    component: () => import("@/views/Pages/AboutEyedock.vue"),
  },
  {
    path: "/:section(documentation|blog|news|page)/:article?",
    name: "PageHome",
    component: () => import("@/views/Pages/PageHome.vue"),
    meta: {
      // hideNavbar: true,
    },
  },
  {
    path: "/:action(login|logout)",
    component: () => import("@/views/Pages/LoginLogout.vue"),
  },

  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("@/views/Pages/Unauthorized.vue"),
    meta: { hideNavbar: true },
  },
  {
    path: "/eyescholar",
    name: "Eye Scholar",
    component: () => import("@/views/Pages/EyeScholar.vue"),
    beforeEnter: guardSubscribed,
    meta: {
      hideNavbar: true,
      // hideHeaderNav: true
    },
  },

  // temp !!!!!
  // {
  //   path: "/tablemaker",
  //   component: () => import("@/components/adminStuff/TableMaker.vue"),
  // },

  // ------ prices ------
  {
    path: "/cl_prices/:page?",
    name: "ClPriceCalculations",
    beforeEnter: guardSubscribed,
    meta: {
      hideNavbar: true,
      hideAd: true,
    },
    component: () => import("@/views/ClPrices/ClPricesHome.vue"),
  },

  // ocular conditions (diseases, DZ)
  {
    path: "/ocular-conditions/",
    name: "Conditions",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/Conditions/Conditions.vue"),
    // children: [
  },
  {
    path: "/uveitis",
    name: "Uveitis",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/Conditions/Uveitis.vue"),
    // children: [
  },
  {
    path: "/ocular-conditions/details/:name",
    name: "ConditionDetails",
    component: () => import("@/views/Conditions/Conditions.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/ocular-conditions/category/:category",
    name: "CategoryConditions",
    component: () => import("@/views/Conditions/Conditions.vue"),
    beforeEnter: guardSubscribed,
  },
  {
    path: "/ocular-conditions/search/:search",
    name: "SearchConditions",
    component: () => import("@/views/Conditions/Conditions.vue"),
    beforeEnter: guardSubscribed,
  },
  //   ],
  // },

  // --- RGPs --------------------------------
  {
    path: "/rgps",
    name: "RgpHome",
    redirect: { path: "rgps/corneal" },
    beforeEnter: guardSubscribed,
    // component: () => import("@/views/RGPs/RgpHome.vue"),
  },
  {
    path: "/rgps/:lensType(corneal|scleral|ortho-k)",
    name: "RgpType",
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },
  {
    path: "/rgps/tag/:tag",
    name: "RgpTag",
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },
  {
    path: "/rgps/companies",
    name: "RgpCompanies",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },

  {
    path: "/rgps/company/:company",
    name: "RgpCompany",
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },
  {
    path: "/rgps/materials/:materialCompany?",
    name: "RgpMaterials",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },

  {
    path: "/rgps/list",
    name: "RgpList",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },
  {
    path: "/rgps/details/:slug",
    name: "RgpDetails",
    beforeEnter: guardSubscribed,
    component: () => import("@/views/RGPs/RgpHome.vue"),
  },

  // ------ references -------

  {
    path: "/clinical-references/:category?/:slug?",
    // beforeEnter: guardSubscribed,
    component: () => import("@/views/References/ReferencesHome.vue"),
  },
  {
    path: "/educational-images/:category?/:slug?",
    // beforeEnter: guardSubscribed,
    component: () => import("@/views/References/ReferencesHome.vue"),
  },
  {
    path: "/billing-protocols",
    // beforeEnter: guardSubscribed,
    component: () => import("@/views/References/ReferencesHome.vue"),
  },

  {
    path: "/cl-products/:slug?",
    // beforeEnter: guardSubscribed,
    component: () => import("@/views/References/ReferencesHome.vue"),
  },
  // {
  //   path: "/educational-images",

  //   component: () => import("@/views/References/ReferencesHome.vue"),
  // },

  // admin stuff
  {
    path: "/boss",
    name: "AdminHome",
    component: () => import("@/views/Admin/AdminHome.vue"),
    children: [
      {
        path: ":page",
        component: () => import("@/views/Admin/AdminHome.vue"),
        name: "AdminPage",
        meta: {
          hideAd: true,
          hideNavbar: true,
        },
        children: [
          {
            path: ":item",
            component: () => import("@/views/Admin/AdminHome.vue"),
            name: "AdminPageItem",
            meta: {
              hideAd: true,
              hideNavbar: true,
            },
          },
        ],
      },
    ],
    meta: {
      // hideNavbar: true,
      hideAd: true,
      hideNavbar: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  hashbang: true,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
      // }
      // else if (to.hash) {
      //   console.log(to.hash);
      //   return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// router.beforeEach((toRoute, fromRoute, next) => {
//   window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'EyeDock/Meds';

//   next();
// })

// const DEFAULT_TITLE = 'EyeDock/Meds';
// // eslint-disable-next-line no-unused-vars
// router.afterEach((to, from) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

export default router;
