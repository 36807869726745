<template>
  <div class="d-print-none">
    <!-- todo - add ability to add a priority message on the backend that can be displayed here -->
    <!-- <div class="ma-2">message!</div> -->
    <!-- {{ currentModule }} -->
    <med-category-picker v-if="currentModule == 'meds'"></med-category-picker>
    <icd-menu-btns v-else-if="currentModule == 'codes'"></icd-menu-btns>
    <billing-menu-btns
      v-else-if="currentModule == 'billingCodes'"
    ></billing-menu-btns>
    <coding-see-also
      v-else-if="currentModule == 'codingWidget'"
    ></coding-see-also>
    <coding-example-menu-btns v-else-if="currentModule == 'coding-examples'" />
    <coding-see-also
      :hideSeeAlso="true"
      v-else-if="currentModule == 'coding-home'"
    ></coding-see-also>
    <billing-protocols-menu-btns
      v-else-if="currentModule == 'billing-protocols'"
    ></billing-protocols-menu-btns>
    <calcs-menu-btns
      v-else-if="
        currentModule == 'calcs' || currentModule == 'vision-simulator'
      "
    ></calcs-menu-btns>
    <cl-menu-btns
      v-else-if="currentModule == 'cls' || currentModule == 'cl-products'"
    ></cl-menu-btns>
    <prices-menu-btns
      v-else-if="currentModule == 'cl_prices'"
    ></prices-menu-btns>
    <conditions-menu-btns
      v-else-if="currentModule == 'disease'"
    ></conditions-menu-btns>
    <rgp-menu-btns
      v-else-if="currentModule == 'rgps' || currentModule == 'hybrids'"
    ></rgp-menu-btns>
    <references-menu-btns
      :currentModule="currentModule"
      v-else-if="
        currentModule == 'clinical-references' ||
        currentModule == 'educational-images'
      "
    ></references-menu-btns>

    <!-- <admin-menu-btns v-else-if="currentModule == 'admin'"></admin-menu-btns> -->
    <documentation-sidebar
      v-else-if="currentModule == 'documentation'"
    ></documentation-sidebar>
    <!-- <hamburger-menu v-else-if="currentModule == 'search-all'"></hamburger-menu> -->
    <!-- <tree-navigation
      v-else-if="currentModule == 'documentation'"
    ></tree-navigation> -->
    <div v-else-if="currentModule == 'blog' || currentModule == 'news'">
      <article-list></article-list>
      <!-- {{ currentModule ? currentModule : " - " }} -->
    </div>
    <div v-else>
      <article-list title="News & Blog Posts"></article-list>
      <!-- {{ currentModule ? currentModule : " - " }} -->
    </div>
    <div style="height: 4em">&nbsp;</div>
  </div>
</template>

<script>
import CurrentModuleMixin from "@/assets/js/CurrentModuleMixin";

export default {
  name: "SidebarMenu",
  components: {
    MedCategoryPicker: () =>
      import("@/components/medStuff/MedCategoryPicker.vue"),
    IcdMenuBtns: () => import("@/components/icdStuff/IcdMenuBtns.vue"),
    BillingMenuBtns: () => import("@/components/icdStuff/BillingMenuBtns.vue"),
    // CodingGeneralBtns: () =>
    //   import("@/components/icdStuff/CodingGeneralBtns.vue"),
    CodingSeeAlso: () => import("@/components/icdStuff/CodingSeeAlso.vue"),
    CalcsMenuBtns: () => import("@/components/calcsStuff/CalcsMenuBtns.vue"),
    PricesMenuBtns: () =>
      import("@/components/clPricesStuff/PricesMenuBtns.vue"),
    ClMenuBtns: () => import("@/components/clStuff/ClMenuBtns.vue"),
    ConditionsMenuBtns: () =>
      import("@/components/conditionsStuff/ConditionsMenuBtns.vue"),
    // AdminMenuBtns: () => import("@/components/adminStuff/AdminMenuBtns.vue"),
    DocumentationSidebar: () =>
      import("@/components/pagesStuff/DocumentationSidebar.vue"),
    ReferencesMenuBtns: () =>
      import("@/components/referencesStuff/ReferencesMenuBtns.vue"),
    RgpMenuBtns: () => import("@/components/gpStuff/RgpMenuBtns.vue"),
    ArticleList: () => import("@/components/pagesStuff/ArticleList.vue"),
    BillingProtocolsMenuBtns: () =>
      import("@/components/referencesStuff/BillingProtocolsMenuBtns.vue"),
    CodingExampleMenuBtns: () =>
      import("@/components/referencesStuff/CodingExampleMenuBtns.vue"),
  },
  mixins: [CurrentModuleMixin],
  props: {},
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
