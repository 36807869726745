<template>
  <v-app-bar
    app
    elevation="1"
    class="d-print-none"
    :class="activeSubscription ? 'headerClassPro' : 'headerClass'"
    clipped-left
    clipped-right
  >
    <v-btn
      icon
      class="d-inline-block d-md-none px-0"
      color="primary"
      @click="$router.go(-1)"
      ><v-icon>mdi-chevron-left</v-icon></v-btn
    >
    <div class="d-flex d-md-none" style="width: 45px">
      <v-img
        src="http://www.eyedock.com/images/eyedock_logo_2.svg"
        height="45"
        contain
        @click="$router.push({ path: '/' })"
      ></v-img>
    </div>

    <div class="d-none d-md-block ml-2 mr-4 text-center">
      <div class="my-0 eyeDockTitle">
        <router-link to="/" class="blue-grey--text text--darken-3">
          <span class="eyeText">Eye</span
          ><span class="dockText">Dock</span></router-link
        >
        <span v-if="activeSubscription" class="proText mx-1">Pro</span>
        <span v-if="currentModuleTitle" class="onlyBig"
          >/<span class="moduleText"> {{ currentModuleTitle }}</span></span
        >
      </div>
      <div class="titleCaption font-weight-light">
        <span class="onlyBig"> powered </span> by
        <a href="https://odsonfinance.com/">ODsonFinance</a>
      </div>
    </div>
    <v-spacer class="d-none d-md-flex"></v-spacer>

    <autocompletePopup :isSubscribed="isSubscribed"></autocompletePopup>

    <div class="d-flex flex-row">
      <div
        v-for="(navItem, index) in navOptionsForSize"
        :key="index"
        class="d-none d-sm-block"
        :style="screenIzBig ? '' : 'width:40px;'"
      >
        <segmented-button
          :data="navItem"
          :smallScreenHideLabel="true"
          :openWithClick="true"
          :isSubscribed="isSubscribed"
          :condense="!screenIzBig"
        >
        </segmented-button>
      </div>
      <div v-if="isStudent && $vuetify.breakpoint.mdAndUp">
        <segmented-button
          :data="eyescholarMenuData"
          :smallScreenHideLabel="true"
          :openWithClick="true"
          :isSubscribed="isSubscribed"
          :condense="!screenIzBig"
        >
        </segmented-button>
      </div>
    </div>
    <div>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="px-0 px-sm-1"
            text
            @click="$store.dispatch('toggleShowQuickCalcs')"
            v-on="on"
          >
            <v-img
              :src="quickCalcsIcon"
              contain
              :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 28"
              max-height="28"
            ></v-img>
          </v-btn> </template
        ><span>Quick Calcs</span>
      </v-tooltip>
    </div>

    <v-spacer class="d-none d-md-flex"></v-spacer>

    <user-login class="d-none d-md-flex"></user-login>
    <v-spacer></v-spacer>
    <hamburger
      :isSubscribed="isSubscribed"
      :isLoggedIn="isLoggedIn"
      :navData="navData"
    ></hamburger>
  </v-app-bar>
</template>

<script>
import Hamburger from "./Hamburger";
import CurrentModuleMixin from "@/assets/js/CurrentModuleMixin";
import SegmentedButton from "@/components/universalStuff/SegmentedButton";
import AutocompletePopup from "./AutocompletePopup.vue";
import UserMixin from "@/assets/js/UserMixin";
import GetJsonMixin from "@/assets/js/GetJsonMixin";

import { mapState } from "vuex";

export default {
  name: "EyedockHeader",
  components: {
    Hamburger,
    SegmentedButton,
    AutocompletePopup,
    UserLogin: () => import("@/components/universalStuff/UserLogin"),
  },
  mixins: [CurrentModuleMixin, UserMixin, GetJsonMixin],
  props: {},
  data: function () {
    return {
      loading: false,
      bigScreenSize: 1200,
      navData: null,
      eyescholarMenuData: {
        label: "Eye Scholar",
        shortLabel: null,
        to: "/eyescholar",
        image: "https://www.eyedock.com/eyescholar_files/Eyescholar_Icon.svg",
        subtitle: "Learn and practice optometry skills",
        children: [
          {
            label: "Eye Scholar",
            image:
              "https://www.eyedock.com/eyescholar_files/Eyescholar_Icon.svg",
            to: "/eyescholar",
          },
        ],
      },
    };
  },
  methods: {},
  computed: {
    ...mapState(["showQuickCalcs"]),
    moduleTitle: function () {
      return this.currentModule;
    },
    // navData: function () {
    //   return NavigationOptions.navTree;
    // },
    quickCalcsIcon: function () {
      let path = "https://www.eyedock.com/images/menu_icons/";
      let file = this.showQuickCalcs
        ? "quick_calcs_icon_up.svg"
        : "quick_calcs_icon.svg";
      return path + file;
    },
    navOptionsForSize: function () {
      let nd = this.navData;
      if (!nd) return null;
      let rtn = [];
      let smAndUp = this.$vuetify.breakpoint.smAndUp;
      nd.forEach((navItem) => {
        let include =
          !navItem.only ||
          (navItem.only == "sm" && smAndUp) ||
          (navItem.only == "xs" && !smAndUp);
        if (include) rtn.push(navItem);
      });
      return rtn;
    },
    screenIzBig: function () {
      return this.$vuetify.breakpoint.width >= this.bigScreenSize;
    },
  },
  mounted: function () {
    this.getJSON("mainNavigation", "navData"); // in the mixin
    this.setCurrentModule(this.$route.path);
  },
};
</script>

<style scoped>
.logoSize {
  width: 60px;
}

.max100 {
  max-width: 100%;
}
.autocompleteDiv {
  width: 380px;
}
.proText {
  color: #c18b05;
  font-weight: 500;
  /* font-weight: 500; */
}
.eyeDockTitle {
  font-size: 1.25rem !important;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  cursor: pointer;
}
.headerClass {
  /* background: linear-gradient(#fafdff, #d5e3ef); */
  background: linear-gradient(#d5e3ef, #eef5fa);
}
.headerClassPro {
  /* background: linear-gradient(#fafdff, #d5e3ef); */
  background: linear-gradient(#ffeabc, #fffefc);
}
/* .headerClass {
  background-color: #eaf2f6 !important;
} */
.eyeText {
  font-weight: 300 !important;
}
.dockText {
  font-weight: 600;
  text-transform: uppercase !important;
}
.titleCaption {
  font-size: 11px;
}
@media screen and (max-width: 1550px) {
  .onlyBig {
    display: none;
  }
}
</style>
